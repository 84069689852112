import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { CartItem } from '../../models/cart';
import { ProductService } from '../../services/product.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import * as customerReducer from '../../reducers/customer';
import { CustomerState } from '../../models/customer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-cgc',
  templateUrl: './cgc.component.html',
  styleUrls: ['./cgc.component.scss']
})
export class CgcComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  committeeChairEmail2 = 'katsdogs2@aol.com';
  cgcForm: FormGroup;
  showSpinner: boolean;
  showSpinner2: boolean;
  infoModalRef: NgbModalRef;
  errorModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  private unsubscribe2$ = new Subject<void>();
  myProductIsChanging: boolean;
  itemName = '';
  itemName2 = '';
  itemId = -1;
  itemId2 = -1;
  itemVariants = [];
  tditemVariants = [];
  cgcCost = 0;
  tdCost = 0;
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  productInfo2: any;
  orderTotal = 0;
  cgcDeadline = '';
  tdDeadline = '';
  deadlineTime = 0;
  deadlineTime2 = 0;
  productNotAvailable = true;
  productNotAvailable2 = true;
  soldOut = false;
  soldOut2 = false;
  comingSoon = false;
  comingSoon2 = false;
  availabilityDate = '';
  availabilityDate2 = '';
  availabilityDateTime = 0;
  availabilityDateTime2 = 0;
  cgcTestListArray: FormArray;
  tdTestListArray: FormArray;
  intId = -1;
  intId2 = -1;

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              public store: Store<CustomerState>,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.showSpinner2 = false;
    this.cgcForm = this.fb.group({
      cgcTests: this.fb.array([]),
      tdTests: this.fb.array([])
    });
    this.cgcTestListArray = this.cgcForm.get('cgcTests') as FormArray;
    this.tdTestListArray = this.cgcForm.get('tdTests') as FormArray;

    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {
          this.intId = 1;
          this.api.get('/products?category=32&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  // returned an array
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.productInfo = res.products[0];
                      if (this.productInfo.hasOwnProperty('id')) {
                        this.itemId = this.productInfo.id;
                      }
                      if (this.productInfo.hasOwnProperty('name')) {
                        this.itemName = this.productInfo.name;
                      }
                      if (this.productInfo.hasOwnProperty('variants')) {
                        this.itemVariants = this.productInfo.variants;
                      }
                      const availabilityMap = this.cartService.productIsAvailable(this.productInfo);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.cgcDeadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;

                      this.itemVariants.forEach((aTest) => {
                        const formGroup: FormGroup = this.fb.group({
                          quantity: this.fb.control(0)
                        });
                        aTest.price = this.productService.getItemPrice(this.productInfo, aTest.skuVariant, this.cartService.isMember);
                        aTest.subTotal = 0;
                        this.cgcTestListArray.push(formGroup);
                      });
                    }
                  }
                  this.showSpinner = false;
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of cgc test products.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
        if ((customer.token !== '') && (this.intId2 === -1)) {
          this.intId2 = 0;
          this.api.get('/products?category=36&variants=true')
          .pipe(takeUntil(this.unsubscribe2$))
          .subscribe(
            (res) => {
              this.ngZone.run(() => {
                // returned an array
                if (res.hasOwnProperty('products')) {
                  if (res.products.length > 0) {
                    this.productInfo2 = res.products[0];
                    if (this.productInfo2.hasOwnProperty('committee')) {
                      const theCommittee = this.productInfo2.committee;
                      this.committeeChairEmail = theCommittee.contactEmail;
                      this.committeeChairName = theCommittee.contactName;
                    }
                    if (this.productInfo2.hasOwnProperty('id')) {
                      this.itemId2 = this.productInfo2.id;
                    }
                    if (this.productInfo2.hasOwnProperty('name')) {
                      this.itemName2 = this.productInfo2.name;
                    }
                    if (this.productInfo2.hasOwnProperty('variants')) {
                      this.productInfo2.variants.forEach((aTest) => {
                        const formGroup: FormGroup = this.fb.group({
                          quantity: this.fb.control(0)
                        });
                        aTest.price = this.productService.getItemPrice(this.productInfo, aTest.skuVariant, this.cartService.isMember);
                        aTest.subTotal = 0;
                        this.tdTestListArray.push(formGroup);
                      });
                      this.tditemVariants = this.productInfo2.variants;
                    }
                    const availabilityMap = this.cartService.productIsAvailable(this.productInfo2);
                    this.productNotAvailable2 = availabilityMap.productNotAvailable;
                    this.availabilityDate2 = availabilityMap.availabilityDate;
                    this.availabilityDateTime2 = availabilityMap.availabilityDateTime;
                    this.deadlineTime2 = availabilityMap.deadlineTime;
                    this.tdDeadline = availabilityMap.deadline;
                    this.comingSoon2 = availabilityMap.comingSoon;
                    this.soldOut2 = availabilityMap.soldOut;


                  }
                }
                this.showSpinner2 = false;
              });
            },
            () => {
              this.ngZone.run(() => {
                this.showSpinner2 = false;
                setTimeout(() => {
                  this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                  this.errorModalRef.componentInstance.errorMessage = 'read the list of cgc test products.';
                  this.errorModalRef.componentInstance.longErrorMessage = '';
                }, 500);
              });
            });
          }
      });
    });
    this.api.get('/committees/6')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });

    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  changeCgcQuantity(index: number) {
    const cgcQuant = this.cgcTestListArray.controls[index].value;
    const theVariant = this.itemVariants[index];
    theVariant.subTotal = cgcQuant.quantity * theVariant.price;
    this.cgcCost = 0;
    let loopIndex = 0;
    this.cgcTestListArray.controls.forEach((aCtl) => {
      const aVariant = this.itemVariants[loopIndex];
      this.cgcCost += aCtl.value.quantity * aVariant.price;
      loopIndex += 1;
    });
    this.orderTotal = this.cgcCost + this.tdCost;
  }
  changeTdQuantity(index: number) {
    const tdQuant = this.tdTestListArray.controls[index].value;
    const theVariant = this.tditemVariants[index];
    theVariant.subTotal = tdQuant.quantity * theVariant.price;
    this.tdCost = 0;
    let loopIndex = 0;
    this.tdTestListArray.controls.forEach((aCtl) => {
      const aVariant = this.tditemVariants[loopIndex];
      this.tdCost += aCtl.value.quantity * aVariant.price;
      loopIndex += 1;
    });
    this.orderTotal = this.cgcCost + this.tdCost;
  }

  addToCart() {
    this.cgcForm.markAllAsTouched();
    if (this.orderTotal === 0) {
      setTimeout(() => {
        console.log('no cgc test quantity selected');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'No test quantity selected';
        this.infoModalRef.componentInstance.infoMessage = 'Please select the number of each test you' +
          ' would like and try again.';
      }, 500);
      return;
    }
    const body = {items: []};
    const itemArray = body.items;

    const dPoints = {
      displayName: ''
    };
    let loopIndex = 0;
    this.cgcTestListArray.controls.forEach((aCtl) => {
      const aVariant = this.itemVariants[loopIndex];
      if (aCtl.value.quantity > 0) {
        const aTestItem: CartItem = {
          productId: this.itemId, productVarId: aVariant.id,
          quantity: aCtl.value.quantity, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(aTestItem);
      }
        loopIndex += 1;
    });
    loopIndex = 0;
    this.tdTestListArray.controls.forEach((aCtl) => {
      const aVariant = this.tditemVariants[loopIndex];
      if (aCtl.value.quantity > 0) {
        const aTestItem: CartItem = {
          productId: this.itemId2, productVarId: aVariant.id,
          quantity: aCtl.value.quantity, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(aTestItem);
      }
      loopIndex += 1;
    });
    this.myProductIsChanging = true;
    this.showSpinner = true;
    this.cartService.addMultipleItemsToCart(body);
    this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.itemName + ':' + 'multiple', 1);
    setTimeout(() => {
      this.myForm.resetForm();
      this.cgcForm.reset();
      this.cgcTestListArray.controls.forEach((aCtl) => {
        aCtl.value.quantity = 0;
      });
      this.itemVariants.forEach(aVariant => {
        aVariant.subTotal = 0;
      });
      this.tdTestListArray.controls.forEach((aCtl) => {
        aCtl.value.quantity = 0;
      });
      this.tditemVariants.forEach(aVariant => {
        aVariant.subTotal = 0;
      });
      this.orderTotal = 0;
      this.cgcCost = 0;
      this.tdCost = 0;
    });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribe2$.next();
    this.unsubscribe2$.complete();
  }


}
