<div class="container-fluid">
  <div class="row shop_page_content d-none">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025WorkingDogTeamRelayForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1 d-none" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11">
      <h3>Working Dog Team Relay</h3>
      <p>New this year and shamelessly stolen from an event held by the North Central Newfoundland Club, we are excited
        to introduce this opportunity to the everyone at this year&apos;s National.  Find some other friends attending
        with their dogs and enter.  You don&apos;t have to have any experience, nor does your dog.  It is fun and there are prizes!</p>

      <h4>Interested in entering the Working Dog Team Relay, click this button: <a class="btn btn-info quick_links_action ms-1" href="https://ncnc.wildapricot.org/event-5951685" download="" target="_blank">Entry Website</a></h4>
      <h5 class="mt-1">How does it work?</h5>
      <ul>
        <li>Teams will be composed of 3-6 dog and handler pairs.</li>
        <li>Teams will line up in one of several designated spots around the course.</li>
        <li>One dog and handler pair per team will cross the start line, do their choice of one station, earn points,
          then return and cross the start line again before the next team member enters the course.</li>
        <li>Handling pairs from two different teams will be working course at the same time.</li>
        <li>Each handling pair must enter and exit the course on leash. If you choose to remove the leash while doing
          your station, the dog must always be under control.  Loose, disruptive dogs will be disqualified, and their points forfeited.</li>
        <li>There will be a set time for the teams to execute as many stations as possible. There are no requirements
          about which stations the handlers execute.</li>
        <li>Each station will have a minimum and maximum point value based on degree of difficulty.</li>
        <li>One judge per team. Judges award the points after each handling pair has completed their station.</li>
        <li>Bonus Round - Short bonus round at the end of first score tally. Teams to decide which station(s) to add to their scores.</li>
      </ul>
      <h5>Relay Stations</h5>
      <p class="fw-bold mb-0">Trick</p>
      <ul>
        <li>Handler&apos;s choice, bring your own props</li>
        <li>One part completed (10 points) two parts completed (20 points) three parts completed (30 points)</li>
      </ul>
      <p class="fw-bold mb-0">Rally</p>
      <ul>
        <li>Novice (10 points), Advanced (20 points), Excellent (30 points), Master (40 points)</li>
        <li>3 signs from each level will be provided, choose the level and sign you wish to complete and show it to the judge</li>
      </ul>
      <p class="fw-bold mb-0">Obedience</p>
        <ul>
          <li>Recall from sit position, 6&apos; away (10 points)</li>
          <li>Recall and Finish from sit position, 6&apos; away (20 points)</li>
          <li>Figure Eight, loose leash (20 points)</li>
          <li>Command Discrimination: from heel position or directly in front of dog (20 points) from 6&apos; away/end of
          leash (30 points) your choice of order: sit, stand, down</li>
        </ul>
      <p class="fw-bold mb-0">Draft (dogs hitched before entering the course)</p>
      <ul>
        <li>90 degree turn. Marked lanes like a dart board. 18&quot; lane (30 points) 24&quot; lane (20 points) 36&quot; lane (10 points)</li>
        <li>If the inside wheel crosses the line to the wider, outer lane, that point value will be awarded</li>
        <li>Backwards 90 degree turn within 24” lane (40 points) (20 points for incomplete or out of bounds)</li>
        <li>Figure 8. (20 points)</li>
        <li>Driven Figure 8 (40 points) (20 points for incomplete)</li>
        <li>Serpentine (20 points)</li>
      </ul>
      <p class="fw-bold mb-0">Water</p>
      <ul>
        <li>Go out and around a cone (10 points) from 6&apos; away</li>
        <li>Go out and around a cone with bumper in their mouth (20 points)</li>
        <li>Underwater retrieve (20 points)</li>
        <li>Go out to a bumper attached to a full water jug, pull the jug minimum 6 feet, deliver bumper to hand (30 points)</li>
      </ul>
      <p>Any station attempted but not completed properly will earn minimum 10 points. Judges discretion for additional partial credit.</p>

    </div>
  </div>
</div>

