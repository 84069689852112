<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-12 col-sm-12 mx-auto">
      <header>
        <h3>2025 Regional Trophies </h3>
        <div class="row">
          <div class="col-md-9">
            <p>This year, there will be two AKC Events hosted by two different regional Clubs, an AKC Rally trial hosted by GRNC and an AKC Obedience trial hosted by BMNC.  Each club is offering their own trophies, so the clubs would really appreciate your donation toward the trophy cost.</p>
            <p>The trophies are custom designed medallions on a neck ribbon done by Prairie Dog Pottery.</p>
            <p>Each sponsorship includes four class placements (first through fourth place). i.e. Someone sponsoring Novice A for $65.00 is sponsoring first through fourth place for that class at a total of $65.00.</p>
          </div>
        </div>
      </header>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-8">
      <p class="mt-1">Please fill out the sponsorship field carefully, the premium list will be printed from this submission.</p>
      <p class="mt-1">If you have questions, please contact <a href="mailto:{{committeeChairEmail}}">{{ committeeChairName }}</a></p>
      <p>Deadline is {{ deadline }}.</p>
      <p *ngIf="productNotAvailable"><strong>Trophies are not available for sale at this time.</strong></p>
      <p *ngIf="pastDeadline"><strong>Trophies are no longer available for sale</strong></p>
    </div>
    <div class="col-md-4">
      <img src="assets/2025/GRNCLogo.png" class="w-50" alt="GRNC Logo">
      <img src="assets/2025/BMNC.jpeg" class="w-50" alt="BMNC Logo">
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <h2>BMNC Obedience Trial Trophies</h2>
          <table class="table table-bordered table-hover table-sm">
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>Price</td>
              <td class="text-center">Add to cart</td>
            </tr>
            <tr class="orderRow" *ngFor="let item of bmncTrophyItems">
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td class="text-end">{{ getPrice(item) | currency }}</td>
              <td class="text-center"><button type="button" class="mt-auto mx-auto add-to-cart-button" (click)="addToCart(item)" [disabled]="isSoldOut(item) || productNotAvailable">Add to Cart</button></td>
            </tr>
          </table>
        </div>
        <div class="loaded-content" [class.blurred]="showSpinner">
          <h2>GRNC Rally Trial Trophies</h2>
          <table class="table table-bordered table-hover table-sm">
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>Price</td>
              <td class="text-center">Add to cart</td>
            </tr>
            <tr class="orderRow" *ngFor="let item of trophyItems">
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td class="text-end">{{ getPrice(item) | currency }}</td>
              <td class="text-center"><button type="button" class="mt-auto mx-auto add-to-cart-button" (click)="addToCart(item)" [disabled]="isSoldOut(item) || productNotAvailable">Add to Cart</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

