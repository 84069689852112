import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { CartItem } from '../../models/cart';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { ProductService } from '../../services/product.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import { Store } from '@ngrx/store';
import { CustomerState } from '../../models/customer';
import * as customerReducer from '../../reducers/customer';

@Component({
  selector: 'app-grooming',
  templateUrl: './grooming.component.html',
  styleUrls: ['./grooming.component.scss']
})
export class GroomingComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  groomForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  infoModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  myProductIsChanging: boolean;
  intItemName = '';
  intItemId = -1;
  groomTypes = [];
  reservedCost = 0;
  additionalCost = 0;
  reservedTotalCost = 0;
  additionalTotalCost = 0;
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  orderTotal = 0;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  canBuyAdditional = false;
  addtionalAvailable = false;
  intId = -1;

  accountValidationMessages = {
    days: [
      { type: 'required', message: 'Days needed information is required' }
    ]
  };

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              public store: Store<CustomerState>,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.groomForm = this.fb.group({
      nextTo: [{value: '', disabled: false}],
      days: [{value: '', disabled: false}, Validators.required],
      resQuantity: [{value: 0, disabled: false}],
      addQuantity: [{value: 0, disabled: true}],
      addOutQuantity: [{value: 0, disabled: false}]
    });
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {
          this.intId = 1;
          this.api.get('/products?category=6&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  // returned an array
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.productInfo = res.products[0];
                      if (this.productInfo.hasOwnProperty('id')) {
                        this.intItemId = this.productInfo.id;
                      }
                      if (this.productInfo.hasOwnProperty('name')) {
                        this.intItemName = this.productInfo.name;
                      }
                      if (this.productInfo.hasOwnProperty('variants')) {
                        this.groomTypes = this.productInfo.variants;
                      }
                      const availabilityMap = this.cartService.productIsAvailable(this.productInfo);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.deadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;

                      this.reservedCost = this.productService.getItemPrice(this.productInfo, 0, this.cartService.isMember);
                      this.additionalCost = this.productService.getItemPrice(this.productInfo, 200, this.cartService.isMember);
                    }
                  }
                  this.showSpinner = false;
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of grooming products.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      });
    });
    this.api.get('/committees/16')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  changeQuantity() {
    const resQuant = this.groomForm.controls.resQuantity.value;
    this.reservedTotalCost = resQuant * this.reservedCost;
    this.canBuyAdditional = resQuant > 0;
    if (this.canBuyAdditional) {
      this.groomForm.controls.addQuantity.enable();
    } else {
      this.groomForm.controls.addQuantity.setValue(0);
      this.groomForm.controls.addQuantity.disable();
    }
    const addQuant = this.groomForm.controls.addQuantity.value;
    this.additionalTotalCost = addQuant * this.additionalCost;
    const addOutQuant = this.groomForm.controls.addOutQuantity.value;
    this.orderTotal = this.reservedTotalCost + this.additionalTotalCost;
  }

  addToCart() {
    this.groomForm.markAllAsTouched();
    if (this.groomForm.valid) {
      const resQuant = this.groomForm.controls.resQuantity.value;
      const addQuant = this.groomForm.controls.addQuantity.value;
      const addOutQuant = this.groomForm.controls.addOutQuantity.value;
      if (resQuant + addQuant + addOutQuant === 0) {
        setTimeout(() => {
          console.log('invalid adtype');
          this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
          this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
          this.infoModalRef.componentInstance.infoMessage = 'Please select the type of grooming space you would like and try again.';
        }, 500);
        return;
      }
      const body = {items: []};
      const itemArray = body.items;

      // get all grooming spaces
      if (resQuant > 0) {
        const dPoints = {
          'Place me next to': this.groomForm.controls.nextTo.value,
          'Days Space is needed': this.groomForm.controls.days.value,
          displayName: ''
        };
        const firstReg: CartItem = {
          productId: this.intItemId, productVarId: this.groomTypes[0].id,
          quantity: resQuant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      if (addQuant > 0) {
        const dPoints = {
          'Place me next to': this.groomForm.controls.nextTo.value,
          'Days Space is needed': this.groomForm.controls.days.value,
          displayName: ''
        };
        const secondReg: CartItem = {
          productId: this.intItemId, productVarId: this.groomTypes[1].id,
          quantity: addQuant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(secondReg);
      }
      if (addOutQuant > 0) {
        const dPoints = {
          'Place me next to': this.groomForm.controls.nextTo.value,
          'Days Space is needed': this.groomForm.controls.days.value,
          displayName: ''
        };
        const thirdReg: CartItem = {
          productId: this.intItemId, productVarId: this.groomTypes[2].id,
          quantity: addOutQuant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(thirdReg);
      }

      this.myProductIsChanging = true;
      this.showSpinner = true;
      this.cartService.addMultipleItemsToCart(body);
      this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.intItemName + ':' + 'multiple', 1);
      setTimeout(() => {
        this.myForm.resetForm();
        this.groomForm.reset({
          nextTo: '',
          days: '',
          resQuantity: 0,
          addQuantity: 0,
          addOutQuantity: 0
        });
      });
    } else {
      setTimeout(() => {
        console.log('invalid adtype');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to submit form.';
        this.infoModalRef.componentInstance.infoMessage = 'Please enter the days that you need the space and try again.';
      }, 500);
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
