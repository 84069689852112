<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025SeminarForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto:{{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-8 col-xs-12">
      <div class="row">
        <div class="col-md-10">
          <h3>HEALTH &amp; LONGEVITY EDUCATION SEMINAR</h3>
          <div>
            <div class="text-center fw-bold fs-3">
              <p>Big Dogs Should Not Cry:</p>
              <p>It is a common misconception that big dogs should limp - they should not!</p>
            </div>
            <div class="text-start">
              <p>Big Dogs Need Big Butts: Well, maybe not only big butts, but they certainly need a good
                amount of muscle mass, proper warm up and cool downs, and a partner that may recognize
                trouble if it occurs. This talk will focus on the musculoskeletal health of your beloved
                Newfoundland. Proper fitness, care, and precautions will be discussed for the Newfoundland of
                all ages.</p>
            </div>
            <div class="text-center">
              <p>By Debbie Gross Torraca, DPT, MSPT, Diplomat ABPTS, CCRP from Wizard of Paws, Colchester, CT</p>
              <p>Please register online ($10/person) to attend this seminar.
                There will be demonstration of warm up/cool down for dog sports.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10">
          <h5>The NCA Health and Longevity Committee is very pleased to have Internationally renowned Canine Physical Therapy, Dr. Debbie Torraca speak at our national this year! Dr. Torraca founded the Wizard of Paws Physical Rehabilitation for Animals practice in Colchester CT.</h5>
          <p>Debbie says, &quot;I believe EVERY dog deserves the best quality of life for the longest time
            possible - does not matter if they are 8 weeks of age or 18 years of age.
            Wizard of Paws focuses on that with every animal that walks in the door.</p>
          <p>My education started in human physical therapy but while I was going through
            physical therapy school, I quickly realized that my family dog as well as all
            other animals could benefit from the science of physical therapy as much as
            people could. As I continued with my human physical therapy career, I kept the
            dream of treating animals in the back of my mind while focusing on the sports
            medicine and orthopedics. While I was doing this, my quest for animal therapy
            continued and I studied as much canine anatomy, movement and treatment as
            I could.</p>
          <p>Over the years, I worked at a veterinarian practice and reduced my hours at my
            human clinic. At some point, I realized my mind and heart was with the
            animals and left the human practice. Twenty five years later, here I am. I love
            working with animals and their owners in the clinic, virtually, and teaching.</p>
          <p>I have been very fortunate to have so many people support me along my
            journey - and have made everlasting friends. Our family owns two Clumber
            Spaniels and a rescue Cocker Spaniel, along with a multitude of salt water fish,
            and a chameleon.&quot;</p>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-xs-12">
      <h2 class="mt-3 float-none">Register for the Health &amp; Longevity Seminar</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>
        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                availabilityDate }}.</h5>
            <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
            <form id="ticketForm" [formGroup]="ticketForm" #lf2="ngForm">
              <div class="table-responsive">
                <table formArrayName="tickets" id="cart" class="table table-hover table-condensed table-parking">
                  <thead>
                  <tr>
                    <th style="width:40%">Product</th>
                    <th style="width:10%">Price</th>
                    <th style="width:5%">Quantity</th>
                    <th style="width:10%" class="text-center">Subtotal</th>
                  </tr>
                  </thead>
                  <tbody id="modalTableBody">
                  <tr class="product" *ngFor="let type of ticketVariants;let i = index" [formGroupName]="i">
                    <td data-th="Product">{{ type.displayName }}</td>
                    <td class="object-price">{{ type.price | currency}}</td>
                    <td data-th="Quantity">
                      <input type="number" class="form-control text-center product-quantity" value="0" min="0"
                             (change)="changeQuantity(i)" formControlName="quantity">
                    </td>
                    <td class="text-center product-subtotal">{{ type.subTotal | currency }}</td>
                  </tr>

                  </tbody>
                  <tfoot>
                  <tr class="visible-xs">
                    <td colspan="4" id="sm-order-total" class="text-center">{{ orderTotal | currency }}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                      availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-xs-12 d-none">
      <h3>Register for Education Seminar</h3>
      <form id="seminarForm" [formGroup]="seminarForm" #lf="ngForm">
        <div class="col-md-12">
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Full Name" formControlName="name">
              <mat-error *ngFor="let validation of accountValidationMessages.name">
                <mat-error class="error-message"
                           *ngIf="seminarForm.get('name').hasError(validation.type) && (seminarForm.get('name').dirty || seminarForm.get('name').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Email Address" formControlName="emailAddress">
              <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                <mat-error class="error-message"
                           *ngIf="seminarForm.get('emailAddress').hasError(validation.type) && (seminarForm.get('emailAddress').dirty || seminarForm.get('emailAddress').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row mt-3 mb-0">
            <mat-form-field class="inputField-half-width-left0">
              <input matInput id="num" type="number" formControlName="num"
                     placeholder="Number in your party">
              <mat-error *ngFor="let validation of accountValidationMessages.num">
                <mat-error class="error-message"
                           *ngIf="seminarForm.get('num').hasError(validation.type) && (seminarForm.get('num').dirty || seminarForm.get('num').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-row mb-3">
            <mat-form-field class="inputField-half-width-left0">
              <mat-label>Are you an NCA Member?</mat-label>
              <mat-select formControlName="isMember" placeholder="NCA Member?">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
              <mat-error *ngFor="let validation of accountValidationMessages.isMember">
                <mat-error class="error-message"
                           *ngIf="seminarForm.get('isMember').hasError(validation.type) && (seminarForm.get('isMember').dirty || seminarForm.get('isMember').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <button type="button" (click)="sendForm()" class="btn btn-info quick_links_action">Send Form</button>
        </div>
      </form>
    </div>

  </div>
</div>
