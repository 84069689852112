import { Injectable, NgZone } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import * as customerReducer from '../reducers/customer';
import { Customer, CustomerState, ShippingAddress } from '../models/customer';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';

@Injectable()
export class CartService {

  private currentCart: BehaviorSubject<any>;
  private cartChanging: BehaviorSubject<boolean>;
  currentCustomer: CustomerState;
  private acartId: number;
  public cartTotal: number;
  public isMember: boolean;
  public isEarlyAccessMember: boolean;
  public isMaintainer: boolean;
  private copyCart: any;
  private errorModalRef: NgbModalRef;
  private userHasSeenXMASAlert: boolean;
  private infoModalRef: NgbModalRef;

  constructor( private apiService: ApiService,
               private ngZone: NgZone,
               public store: Store<CustomerState>,
               private modalService: NgbModal) {
    this.currentCart = new BehaviorSubject<any>({});
    this.cartChanging = new BehaviorSubject(false);
    this.acartId = 0;
    this.cartTotal = 0.0;
    this.copyCart = {};
    this.isMember = false;
    this.isEarlyAccessMember = false;
    this.isMaintainer = false;
    this.userHasSeenXMASAlert = false;
    this.checkStart();
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        this.currentCustomer = customer;
        this.isMember = this.currentCustomer.customer.waId !== '';
        this.isEarlyAccessMember = this.isMember || this.currentCustomer.customer.isRegionalClubMember;
        this.isMaintainer = this.currentCustomer.customer.maintain;
        this.cartChanging.next(false);
      });
    });
  }
  get cartId(): number {
    return this.acartId;
  }

  set cartId(value: number) {
    this.acartId = value;
    this.readCart();
  }

  private readCart() {
    this.apiService.get('/orders/' + this.acartId)
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (res.hasOwnProperty('id')) {
            this.copyCart = res;
            if (this.copyCart.hasOwnProperty('totalCost')) {
              this.cartTotal = this.copyCart.totalCost;
            }
            if (res.hasOwnProperty('customer')) {
              this.isMember = res.customer.waId != null;
            } else {
              this.isMember = false;
            }
          } else {
            this.isMember = false;
          }

          this.currentCart.next(res);
        });
      },
        () => {
          this.ngZone.run(() => {
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read your current cart.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
  }

  public productIsAvailable(inProduct: any) {
    let deadlineTime = 0;
    let deadline = '';
    let availabilityDateTime = 0;
    let availabilityDate = '';
    let productNotAvailable = true;
    let comingSoon = false;
    let soldOut = false;

    if (inProduct.hasOwnProperty('deadline')) {
      deadlineTime = inProduct.deadline;
      deadline = moment(deadlineTime).format('dddd, MMMM Do YYYY');
    }
    if (inProduct.hasOwnProperty('availabilityDate')) {
      availabilityDateTime = inProduct.availabilityDate;
      availabilityDate = moment(availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
    }
    if (moment().isBefore(moment(availabilityDateTime))) {
      // the product is not available yet
      // check if this product has a Member Early Access date
      if (inProduct.memberAvailabilityDate != null) {
        if (this.isEarlyAccessMember) {
          availabilityDateTime = inProduct.memberAvailabilityDate;
          availabilityDate = moment(availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
          if (moment().isAfter(moment(availabilityDateTime))) {
            productNotAvailable = false;
          } else {
            // check if the environment allows early ordering - testing
            if (environment.testProductMode) {
              productNotAvailable = false;
            } else {
              comingSoon = true;
            }
          }
        }
      } else {
        // check if the environment allows early ordering - testing
        if (environment.testProductMode) {
          productNotAvailable = false;
        } else {
          comingSoon = true;
        }
      }
    } else if (moment().isAfter(moment(deadlineTime))) {
      // the product is no longer available
      if (this.isMaintainer) {
        productNotAvailable = false;
        comingSoon = false;
      } else {
        comingSoon = false;
      }
    } else {
      // it is in the open window period (after availability but before deadline)
      productNotAvailable = false;
    }
    if (inProduct.hasOwnProperty('soldOut') && (inProduct.soldOut)) {
      soldOut = true;
      productNotAvailable = true;
    }
    return {
      availabilityDate: availabilityDate,
      availabilityDateTime: availabilityDateTime,
      deadline: deadline,
      deadlineTime: deadlineTime,
      productNotAvailable: productNotAvailable,
      comingSoon: comingSoon,
      soldOut: soldOut
    };
  }
  public getCart(): BehaviorSubject<any> {
    return this.currentCart;
  }

  public getCartChangingNotification(): BehaviorSubject<boolean> {
    return this.cartChanging;
  }

  public isCustomerMember(): boolean {
    if ((this.copyCart != null) && (this.copyCart.customer != null)) {
      return this.copyCart.customer.waId != null;
    } else {
      return false;
    }
  }
  public addItemToCart(item) {
    this.cartChanging.next(true);
    const body = new HttpParams()
      .set('productId', item.productId)
      .set('productVariantId', item.productVariantId)
      .set('quantity', item.quantity)
      .set('dataPoints', item.dataPoints);

    this.apiService.putForm('/orders/' + this.acartId + '/addProduct', body)
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (res.hasOwnProperty('status')) {
            this.cartChanging.next(false);
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = '';
              this.errorModalRef.componentInstance.longErrorMessage = res.status + '. Please try again.';
            }, 500);
          } else {
            this.readCart();
            this.cartChanging.next(false);
          }
        });
      },
        () => {
          this.ngZone.run(() => {
            this.cartChanging.next(false);
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'add an item to your cart.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
  }

  public addMultipleItemsToCart(body) {
    this.cartChanging.next(true);

    this.apiService.put('/orders/' + this.acartId + '/addProducts', body)
      .subscribe((res) => {
          this.ngZone.run(() => {
            this.readCart();
            this.cartChanging.next(false);
          });
        },
        () => {
          this.ngZone.run(() => {
            this.cartChanging.next(false);
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'add an item to your cart.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
  }

  public removeProduct(anItemId) {
    this.cartChanging.next(true);
    this.apiService.put('/orders/' + this.cartId + '/removeProduct/' + anItemId, {})
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.readCart();
          this.cartChanging.next(false);
        });
      },
      () => {
      this.ngZone.run(() => {
        this.cartChanging.next(false);
        setTimeout(() => {
          this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
          this.errorModalRef.componentInstance.errorMessage = 'remove an item from your cart.';
          this.errorModalRef.componentInstance.longErrorMessage = '';
        }, 500);
      });
    });
  }

  public changeShippingAddress(shippingAddress: ShippingAddress) {
    this.apiService.put('/orders/' + this.cartId + '/shippingAddress', shippingAddress)
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.readCart();
        });
      });
  }
  public changeShipping(shipWanted: boolean) {
    const pathStr = (shipWanted ? 'shipOn' : 'shipOff');
    this.apiService.put('/orders/' + this.cartId + '/' + pathStr, {})
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.readCart();
        });
      },
        () => {
          this.ngZone.run(() => {
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'change your shipping preference.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
  }

  public checkout(cartAmount, token, args, shippingWanted): Observable<any> {
    return this.apiService.post('/payment', {
      orderId: this.cartId,
      amount: cartAmount,
      stripeToken: token,
      stripeData: args,
      ship: shippingWanted});
  }
  public checkoutMaintain(cartAmount, shippingWanted): Observable<any> {
    return this.apiService.post('/payment/maintain', {
      orderId: this.cartId,
      amount: cartAmount,
      ship: shippingWanted});
  }

  public checkOrder(): Observable<any> {
    return this.apiService.get('/orders/' + this.cartId + '/check');
  }

  public forceCartRefresh() {
    this.readCart();
  }
  // hook to check if we've shown the warning about xmas
  public checkStart() {
/*
    if (!this.userHasSeenXMASAlert) {
      this.userHasSeenXMASAlert = true;
      setTimeout(() => {
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Hurry! Don\'t miss the deadline.';
        this.infoModalRef.componentInstance.infoMessage = 'Order by December 5th to ensure that you will receive your' +
          ' items in time for holiday delivery';
      }, 500);

    }
*/
  }


}
