import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Form, FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { CartItem } from '../../models/cart';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { ProductService } from '../../services/product.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CustomerState } from '../../models/customer';
import * as customerReducer from '../../reducers/customer';

@Component({
  selector: 'app-meals',
  templateUrl: './meals.component.html',
  styleUrls: ['./meals.component.scss']
})
export class MealsComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  @ViewChild('lf2', {static: true}) myForm2: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  mealForm: FormGroup;
  movieForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  myProductIsChanging: boolean;
  intItemName = '';
  intItemId = -1;
  orderTotal = 0;
  basePrice = 0;
  mealVariants = [];
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  mealsListArray: FormArray;
  intId = -1;

  accountValidationMessages = {
    emailAddress: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    name: [
      { type: 'required', message: 'Your name is required' }
    ],
    fullAddress: [
      { type: 'required', message: 'Your full address is required' }
    ],
    names: [
      { type: 'required', message: 'Names of all attendees are required' }
    ],
    phone: [
      { type: 'required', message: 'Your phone number is required' }
    ],
    isMember: [
      { type: 'required', message: 'Please indicate if you are an NCA member or not'}
    ],
    numberInParty: [
      { type: 'min', message: 'The number in your party must be greater than 0' }
    ]
  };
  private infoModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              private router: Router,
              public store: Store<CustomerState>,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) {
  }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = true;
    this.mealForm = this.fb.group({
      meals: this.fb.array([]),
      names: [{value: '', disabled: false}],
      fullAddress: [{value: '', disabled: false}],
      phone: [{value: '', disabled: false}],
      emailAddress: [{value: '', disabled: false}]
    });
    this.mealsListArray = this.mealForm.get('meals') as FormArray;
    this.movieForm = this.fb.group({
      name: [{value: '', disabled: false}, Validators.required],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]],
      numberInParty: [{value: 1, disabled: false}, Validators.min(1)],
      isMember: [{value: '', disabled: false}, Validators.required]
    });
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {
          this.intId = 1;
          this.api.get('/products?category=12&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  // returned an array
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.productInfo = res.products[0];
                      if (this.productInfo.hasOwnProperty('id')) {
                        this.intItemId = this.productInfo.id;
                      }
                      if (this.productInfo.hasOwnProperty('name')) {
                        this.intItemName = this.productInfo.name;
                      }
                      if (this.productInfo.hasOwnProperty('variants')) {
                        this.mealVariants = this.productInfo.variants;
                      }
                      if (this.productInfo.hasOwnProperty('deadline')) {
                        this.deadlineTime = this.productInfo.deadline;
                        this.deadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
                      }
                      if (this.productInfo.hasOwnProperty('availabilityDate')) {
                        this.availabilityDateTime = this.productInfo.availabilityDate;
                        this.availabilityDate = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
                      }
                      // now check availability
                      const availabilityMap = this.cartService.productIsAvailable(this.productInfo);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.deadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;
                    }
                    this.mealVariants.forEach((aMeal) => {
                      const formGroup: FormGroup = this.fb.group({
                        quantity: this.fb.control(0)
                      });
                      aMeal.price = this.productService.getItemPrice(this.productInfo, aMeal.skuVariant, this.cartService.isMember);
                      aMeal.subTotal = 0;
                      this.mealsListArray.push(formGroup);

                    });
                  }
                  this.showSpinner = false;
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of meal products.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      });
    });
    this.api.get('/committees/27')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  changeQuantity(index) {
    const mealQuant = this.mealsListArray.controls[index].value;
    const theVariant = this.mealVariants[index];
    theVariant.subTotal = mealQuant.quantity * theVariant.price;

    if (theVariant.displayName === 'Annual Banquet') {
      if (mealQuant.quantity > 0) {
        this.mealForm.controls['names'].setValidators(Validators.required);
        this.mealForm.controls['emailAddress'].setValidators([Validators.required, Validators.email]);
        this.mealForm.controls['phone'].setValidators(Validators.required);
        this.mealForm.controls['fullAddress'].setValidators(Validators.required);
      } else {
        this.mealForm.controls['names'].clearValidators();
        this.mealForm.controls['emailAddress'].clearValidators();
        this.mealForm.controls['phone'].clearValidators();
        this.mealForm.controls['fullAddress'].clearValidators();
      }
    }
    this.mealForm.controls['names'].updateValueAndValidity();
    this.mealForm.controls['emailAddress'].updateValueAndValidity();
    this.mealForm.controls['phone'].updateValueAndValidity();
    this.mealForm.controls['fullAddress'].updateValueAndValidity();
    this.orderTotal = 0;
    let loopIndex = 0;
    this.mealsListArray.controls.forEach((aCtl) => {
      const aVariant = this.mealVariants[loopIndex];
      this.orderTotal += aCtl.value.quantity * aVariant.price;
      loopIndex += 1;
    });
  }

  addToCart() {
    this.mealForm.markAllAsTouched();
    if (this.orderTotal === 0) {
      setTimeout(() => {
        console.log('invalid form');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'Please select the meals you' +
          ' would like and try again.';
      }, 500);
      return;
    }
    if (!this.mealForm.valid) {
      setTimeout(() => {
        console.log('invalid form');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'Please fill out all of the required fields and try again.';
      }, 500);
      return;
    }
    const body = {items: []};
    const itemArray = body.items;

    let loopIndex = 0;
    this.mealsListArray.controls.forEach((aCtl) => {
      if (aCtl.value.quantity > 0) {
        const aVariant = this.mealVariants[loopIndex];
        let dPoints = {};
        if (aVariant.displayName === 'Annual Banquet') {
          dPoints = {
            names: this.mealForm.controls.names.value,
            fullAddress: this.mealForm.controls.fullAddress.value,
            phone: this.mealForm.controls.phone.value,
            emailAddress: this.mealForm.controls.emailAddress.value
          };
        }
        const firstReg: CartItem = {
          productId: this.intItemId, productVarId: aVariant.id,
          quantity: aCtl.value.quantity, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      loopIndex += 1;
    });

    this.myProductIsChanging = true;
    this.showSpinner = true;
    this.cartService.addMultipleItemsToCart(body);
    this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.intItemName + ':' + 'multiple', 1);
    setTimeout(() => {
      this.myForm.resetForm();
      this.mealForm.reset({
        meals: this.fb.array([]),
        names: '',
        fullAddress: '',
        phone: '',
        emailAddress: ''
      });
    });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  sendForm() {
    if (this.movieForm.valid) {
      this.showSpinner = true;
      this.api.post('/forms', {
        committeeId: 29,
        formData: {
          fullName: this.movieForm.controls.name.value,
          emailAddress: this.movieForm.controls.emailAddress.value,
          ncaMember: this.movieForm.controls.isMember.value,
          numberInParty: this.movieForm.controls.numberInParty.value
        }})
        .subscribe(
          (res) => this.formSent(res),
          (err) => this.formSendFailed(err)
        );
    } else {
      setTimeout(() => {
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'You have not filled out the form completely.  Please try again.';
      }, 500);
    }
  }

  private formSent(res: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.myForm2.resetForm();
      this.movieForm.reset({
        name: '',
        emailAddress: '',
        numberInParty: 1,
        isMember: ''
      });
      this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
      this.infoModalRef.componentInstance.infoTitle = 'Message Sent.';
      this.infoModalRef.componentInstance.infoMessage = 'Your message was successfully sent to the Movie Night coordinator.' +
        '  Thank you.';
    }, 500);
  }

  private formSendFailed(err: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = '';
      this.errorModalRef.componentInstance.longErrorMessage = 'An error occurred while submitting this form.  Please try again.';
    }, 500);
  }

  gotoTop20Page() {
    this.router.navigateByUrl('/shop/top20')
  }
}
