<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-4 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <header>
        <h3>International and Concierge Packages</h3>
        <p>We are excited to offer our international guests from outside North America a selection of all-inclusive
          packages for this year&apos;s show. Whether you&apos;re traveling with or without a dog, we have options to
          make your experience smooth and enjoyable.</p>
        <p>Each package includes registration, tickets to all social and educational events, reserved ringside seating
          in a designated section, and an official show catalog. For those bringing dogs, an additional package option
          includes a reserved grooming space. Weekly rentals of two-person golf carts are also available for added convenience.</p>
        <p>Packages are available for purchase online only. If you're bringing a dog and need assistance with grooming
          equipment, please reach out to the committee chair using the button below</p>
        <p><em>Pre-order Deadline: {{ deadline }}. </em></p>
        <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
          Please email the Committee Chair</a>
        </p>
      </header>
    </div>
  </div>

  <div class="row shop_page_content">
    <div class="col-md-6">
      <h2 class="mt-3 float-none">Online International Package Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-12 col-sm-12">
            <form id="intlForm" [formGroup]="intlForm" #lf="ngForm">
              <p>Yes, I want to order International Packages from the 2025 National Specialty</p>
              <div class="table-responsive">
                <table id="cart" class="table table-hover table-condensed table-grooming">
                  <thead>
                  <tr>
                    <th style="width:40%">Product</th>
                    <th style="width:10%">Price</th>
                    <th style="width:5%">Quantity</th>
                    <th style="width:15%" class="text-center">Subtotal</th>
                  </tr>
                  </thead>
                  <tbody id="modalTableBody">
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Basic Package</p>
                        </div>
                      </div>
                    </td>
                    <td id="intl1Price" class="object-price">{{ intl1Cost | currency }}</td>
                    <td>
                      <input id="intl1Quantity" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="intl1Quantity">
                    </td>
                    <td class="text-center product-subtotal">{{ intl1TotalCost | currency }}</td>
                  </tr>
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Basic Package Plus Grooming Space</p>
                        </div>
                      </div>
                    </td>
                    <td id="intl2Price" class="object-price">{{ intl2Cost | currency }}</td>
                    <td>
                      <input id="intl2Quantity" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="intl2Quantity">
                    </td>
                    <td class="text-center product-subtotal">{{ intl2TotalCost | currency }}</td>
                  </tr>
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Basic Package Plus Golf Cart</p>
                        </div>
                      </div>
                    </td>
                    <td id="intl3Price" class="object-price">{{ intl3Cost | currency }}</td>
                    <td>
                      <input id="intl3Quantity" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="intl3Quantity">
                    </td>
                    <td class="text-center product-subtotal">{{ intl3TotalCost | currency }}</td>
                  </tr>
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Basic Package Plus Grooming and Golf Cart</p>
                        </div>
                      </div>
                    </td>
                    <td id="intl4Price" class="object-price">{{ intl4Cost | currency }}</td>
                    <td>
                      <input id="intl4Quantity" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="intl4Quantity">
                    </td>
                    <td class="text-center product-subtotal">{{ intl4TotalCost | currency }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="visible-xs">
                    <td colspan="4" id="sm-order-total" class="text-center">{{ orderTotal | currency }}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                      availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
