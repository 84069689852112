import { AfterViewChecked, Component, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Customer, CustomerState } from '../models/customer';
import { Observable, Subject } from 'rxjs';
import * as customerReducer from '../reducers/customer';
import * as cartReducer from '../reducers/cart';
import { ApiService } from '../services/api.service';
import * as customerActions from '../actions/customer.actions';
import { CartState } from '../models/cart';
import { CartService } from '../services/cart.service';
import { takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../login/login.component';
import { ErrorModalComponent } from '../error-modal/error-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  cartTitle: string;
  isLoggedIn: boolean;
  customer$: Observable<CustomerState>;
  cart$: Observable<CartState>;
  currentCustomer: CustomerState;
  currentCart: CartState;
  token: string;
  customerName: string;
  public cookieVal: string;
  guest: boolean;
  private unsubscribe$ = new Subject<void>();
  localCart: any;
  totalOrderCost: number;
  isMenuCollapsed = true;
  packetLink: string;
  formLink: string;
  errorModalRef: NgbModalRef;

  @Output() cartChanged = new EventEmitter();

  constructor(private router: Router,
              private ngZone: NgZone,
              private api: ApiService,
              private modalService: NgbModal,
              private cartService: CartService,
              public store: Store<CustomerState>,
              public cartStore: Store<CartState>) {
    this.customerName = '';
    this.localCart = {};
    this.totalOrderCost = 0.0;
    this.packetLink = 'assets/2025/2025InfoPacketFinal.pdf';
    this.formLink = 'assets/2025/2025FormsFinal2.pdf';
/*
    this.store.select(customerReducer.getCustomerState).subscribe((res) => {
      this.ngZone.run(() => {
        this.currentCustomer = res;
        this.customerName = this.currentCustomer.customer.firstName + ' ' + this.currentCustomer.customer.lastName;
      });
    });
    this.cartStore.select(cartReducer.getCartState).subscribe((res2) => {
      this.ngZone.run(() => {
        this.currentCart = res2;
      });
    });
*/
  }

  ngOnInit() {
    this.cartTitle = 'Cart: $0';
    this.currentCustomer = {customer: {waId: '', cartId: 0, lastName: '', firstName: '', identity: '', addressline1: '', addressline2: '',
        addressCity: '', addressState: '', addressZip: '', addressCountry: '', email: '', phone: '', maintain: false, isRegionalClubMember: false}, token: '', authenticated: false};
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        this.token = customer.token;
        this.isLoggedIn = customer.authenticated;
        if (!this.token) {
          // we don't have a token so check the cookie
          this.cookieVal = '';
          const knumDays = 1;
          const value = '; ' + document.cookie;
          const parts = value.split('; natspec2025token=');
          if (parts.length === 2) {
            // found a token in a cookie, try using it
            this.cookieVal = parts.pop().split(';').shift();
            console.log('AppComponent constructor found a cookie with a token');
            const tokenTryStr = 'Bearer ' + this.cookieVal;
            this.api.setAuthToken(tokenTryStr);
//            console.log('in AppComponent constructor setHeader = ' + tokenTryStr);
            this.api.get('/token/validate')
              .subscribe(
                (res) => this.saveData(res),
                (err) => this.saveData(err)
              );
          } else {
            // we don't have a  cookie, so get a guest token to use
            this.api.post('/customers/guest', {})
              .subscribe(
                (res) => this.saveData(res),
                (err) => this.saveErr(err));
          }

        } else {
          this.currentCustomer = customer;
          this.customerName = this.currentCustomer.customer.firstName;
        }
      });
    });
    this.cartService.getCart()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.hasOwnProperty('id')) {
          this.localCart = res;
          if (this.localCart.hasOwnProperty('totalCost')) {
            this.totalOrderCost = this.localCart.totalCost;
          } else {
            this.totalOrderCost = 0.0;
          }
        }
      });
  }

  saveErr(err) {
    console.log('got an error while getting a guest token.  Bail');
    // remove the cookie, but don't dispatch again (which causes an endless loop)
    const date = new Date();
    const knumDays = 1;
    date.setTime(date.getTime() - (knumDays * 24 * 60 * 60 * 1000));
    const expires = '; expires=' + date.toUTCString();
    document.cookie = 'natspec2025token=' + this.currentCustomer.token + expires + '; path=/';
    // clear out the old token too if it exists
    document.cookie = 'natspectoken=' + this.currentCustomer.token + expires + '; path=/';
    this.isLoggedIn = false;
    this.api.setAuthToken('');
    setTimeout(() => {
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = 'initialize the website. Please try to refresh the window or empty your browser cache.';
      this.errorModalRef.componentInstance.longErrorMessage = '';
    }, 500);
  }
  saveData(res) {
    this.guest = false;
    let authenticated = false;
    if (res.hasOwnProperty('guest')) {
      this.guest = res.guest;
    }
    if (res.hasOwnProperty('valid')) {
      this.store.dispatch(new customerActions.SetTokenAction(res.token));
      console.log('dispatching after saving data');
      this.setupToken();
      if (this.guest) {
        const aCustomer = {
          identity: '',
          firstName: res.first,
          lastName: res.last,
          addressline1: res.address,
          addressline2: '',
          addressCity: res.city,
          addressState: res.state,
          addressZip: res.zip,
          addressCountry: (res.country === 'US' ? 'United States' : res.country),
          phone: res.phone,
          email: res.empty,
          waId: '',
          maintain: false,
          isRegionalClubMember: false,
          cartId: res.cartId
        };
        this.store.dispatch(new customerActions.UpdateCustomerAction(aCustomer));
        // see if we're logged in
//        if (res.address !== null) {
//          authenticated = true;
//        } else {
        authenticated = false;
//        }
        this.store.dispatch(new customerActions.SetAuthenticatedAction(authenticated));
      } else {
        const aCustomer = {
          identity: '',
          firstName: res.first,
          lastName: res.last,
          addressline1: res.address,
          addressline2: '',
          addressCity: res.city,
          addressState: res.state,
          addressZip: res.zip,
          addressCountry: (res.country === 'US' ? 'United States' : res.country),
          phone: res.phone,
          email: res.empty,
          waId: res.myId,
          maintain: (res.hasOwnProperty('maintain')),
          isRegionalClubMember: (res.hasOwnProperty('isRegionalClubMember')),
          cartId: res.cartId};
        this.store.dispatch(new customerActions.UpdateCustomerAction(aCustomer));
        this.store.dispatch(new customerActions.SetAuthenticatedAction(true));
      }

      this.customerName = res.first;
      this.cartService.cartId = res.cartId;
    } else {
      this.logout();
    }
  }

  setupToken() {
    console.log('login.saveData calling setHeaders and passing tokenStr ');
    const tokenStr = 'Bearer ' + this.currentCustomer.token;
    this.api.setAuthToken(tokenStr);

    console.log('saving cookie for later use');
    const date = new Date();
    const knumDays = 30;
    date.setTime(date.getTime() + (knumDays * 24 * 60 * 60 * 1000));
    const expires = '; expires=' + date.toUTCString();
    document.cookie = 'natspec2025token=' + this.currentCustomer.token + expires + '; path=/';

//    console.log('token = ' + this.currentCustomer.token);
  }
  showCart() {
    this.cartChanged.emit(1);
  }

  login() {
    const modalRef = this.modalService.open(LoginComponent, { size: 'lg' });
  }

  logout() {
    const date = new Date();
    const knumDays = 1;
    date.setTime(date.getTime() - (knumDays * 24 * 60 * 60 * 1000));
    const expires = '; expires=' + date.toUTCString();
    document.cookie = 'natspec2025token=' + this.currentCustomer.token + expires + '; path=/';
    // clear out the old token too if it exists
    document.cookie = 'natspectoken=' + this.currentCustomer.token + expires + '; path=/';
    this.isLoggedIn = false;
    this.api.setAuthToken('');
    this.store.dispatch(new customerActions.LogoutCustomerAction());
  }

  registerNow() {
    this.router.navigateByUrl('shop/register')
  }
  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
