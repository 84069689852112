import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import { takeUntil } from 'rxjs/operators';
import * as customerReducer from '../../reducers/customer';
import { CustomerState } from '../../models/customer';
import * as moment from 'moment-timezone';
import { Store } from '@ngrx/store';
import { ProductService } from '../../services/product.service';
import { CartItem } from '../../models/cart';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';

@Component({
  selector: 'app-seminars',
  templateUrl: './seminars.component.html',
  styleUrls: ['./seminars.component.scss']
})
export class SeminarsComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  @ViewChild('lf2', {static: true}) orderForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman';
  private unsubscribe$ = new Subject<void>();

  seminarForm: FormGroup;
  ticketForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  private infoModalRef: NgbModalRef;
  myProductIsChanging: boolean;
  intItemName = '';
  intItemId = -1;
  orderTotal = 0;
  basePrice = 0;
  ticketVariants = [];
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  ticketsListArray: FormArray;
  intId = -1;

  accountValidationMessages = {
    emailAddress: [
      {type: 'required', message: 'Email is required'},
      {type: 'email', message: 'Enter a valid email'}
    ],
    name: [
      {type: 'required', message: 'Your name is required'}
    ],
    isMember: [
      {type: 'required', message: 'Please indicate if you are an NCA member or not'}
    ],
    num: [
      {type: 'min', message: 'The number in your party must be greater than 0'}
    ]
  };

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private ngZone: NgZone,
              public store: Store<CustomerState>,
              private productService: ProductService,
              private modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) {
  }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = true;
    this.seminarForm = this.fb.group({
      name: [{value: '', disabled: false}, Validators.required],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]],
      num: [{value: 1, disabled: false}, Validators.min(1)],
      isMember: ['', Validators.required]
    });
    this.ticketForm = this.fb.group({
      tickets: this.fb.array([])
    });
    this.ticketsListArray = this.ticketForm.get('tickets') as FormArray;
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {
          this.intId = 1;
          this.api.get('/products?category=50&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  // returned an array
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.productInfo = res.products[0];
                      if (this.productInfo.hasOwnProperty('id')) {
                        this.intItemId = this.productInfo.id;
                      }
                      if (this.productInfo.hasOwnProperty('name')) {
                        this.intItemName = this.productInfo.name;
                      }
                      if (this.productInfo.hasOwnProperty('variants')) {
                        this.ticketVariants = this.productInfo.variants;
                      }
                      if (this.productInfo.hasOwnProperty('deadline')) {
                        this.deadlineTime = this.productInfo.deadline;
                        this.deadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
                      }
                      if (this.productInfo.hasOwnProperty('availabilityDate')) {
                        this.availabilityDateTime = this.productInfo.availabilityDate;
                        this.availabilityDate = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
                      }
                      // now check availability
                      const availabilityMap = this.cartService.productIsAvailable(this.productInfo);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.deadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;
                    }
                    this.ticketVariants.forEach((aTicket) => {
                      const formGroup: FormGroup = this.fb.group({
                        quantity: this.fb.control(0)
                      });
                      aTicket.price = this.productService.getItemPrice(this.productInfo, aTicket.skuVariant, this.cartService.isMember);
                      aTicket.subTotal = 0;
                      this.ticketsListArray.push(formGroup);

                    });
                  }
                  this.showSpinner = false;
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of meal products.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      });
    });

    this.api.get('/committees/13')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  changeQuantity(index) {
    const ticketQuant = this.ticketsListArray.controls[index].value;
    const theVariant = this.ticketVariants[index];
    theVariant.subTotal = ticketQuant.quantity * theVariant.price;

    this.orderTotal = 0;
    let loopIndex = 0;
    this.ticketsListArray.controls.forEach((aCtl) => {
      const aVariant = this.ticketVariants[loopIndex];
      this.orderTotal += aCtl.value.quantity * aVariant.price;
      loopIndex += 1;
    });
  }

  addToCart() {
    this.ticketForm.markAllAsTouched();
    if (this.orderTotal === 0) {
      setTimeout(() => {
        console.log('invalid form');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'Please select the tickets you' +
          ' would like and try again.';
      }, 500);
      return;
    }
    if (!this.ticketForm.valid) {
      setTimeout(() => {
        console.log('invalid form');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'Please fill out all of the required fields and try again.';
      }, 500);
      return;
    }
    const body = {items: []};
    const itemArray = body.items;

    let loopIndex = 0;
    this.ticketsListArray.controls.forEach((aCtl) => {
      if (aCtl.value.quantity > 0) {
        const aVariant = this.ticketVariants[loopIndex];
        let dPoints = {};
        const firstReg: CartItem = {
          productId: this.intItemId, productVarId: aVariant.id,
          quantity: aCtl.value.quantity, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      loopIndex += 1;
    });

    this.myProductIsChanging = true;
    this.showSpinner = true;
    this.cartService.addMultipleItemsToCart(body);
    this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.intItemName + ':' + 'multiple', 1);
    setTimeout(() => {
      this.orderForm.resetForm();
      this.ticketForm.reset({
        tickets: this.fb.array([])
      });
    });
  }

  sendForm() {
    if (this.seminarForm.valid) {
      this.showSpinner = true;
      this.api.post('/forms', {
        committeeId: 13,
        formData: {
          fullName: this.seminarForm.controls.name.value,
          emailAddress: this.seminarForm.controls.emailAddress.value,
          ncaMember: this.seminarForm.controls.isMember.value,
          numberInParty: this.seminarForm.controls.num.value
        }
      })
        .subscribe(
          (res) => this.formSent(res),
          (err) => this.formSendFailed(err)
        );
    } else {
      setTimeout(() => {
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = '';
        this.errorModalRef.componentInstance.longErrorMessage = 'You have not filled out the form completely.  Please try again.';
      }, 500);
    }
  }

  private formSent(res: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.myForm.resetForm();
      this.seminarForm.reset({
        name: '',
        emailAddress: '',
        num: 1,
        isMember: ''
      });
      this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
      this.infoModalRef.componentInstance.infoTitle = 'Message Sent.';
      this.infoModalRef.componentInstance.infoMessage = 'Your message was successfully sent to the Health &amp; Longevity Seminar coordinator.' +
        '  Thank you.';
    }, 500);
  }

  private formSendFailed(err: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = '';
      this.errorModalRef.componentInstance.longErrorMessage = 'An error occurred while submitting this form.  Please try again.';
    }, 500);
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
