import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { CartItem } from '../../models/cart';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { ProductService } from '../../services/product.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import * as customerReducer from '../../reducers/customer';
import { CustomerState } from '../../models/customer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-parking',
  templateUrl: './parking.component.html',
  styleUrls: ['./parking.component.scss']
})
export class ParkingComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  parkForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  infoModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  myProductIsChanging: boolean;
  intItemName = '';
  intItemId = -1;
  parkTypes = [];
  standardCost = 0;
  largerCost = 0;
  standardTotalCost = 0;
  largerTotalCost = 0;
  orderTotal = 0;
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  intId = -1;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  parkTypeListArray: FormArray;

  accountValidationMessages = {
    arrival: [
      { type: 'required', message: 'The Date/time of your arrival is required' }
    ],
    departure: [
      { type: 'required', message: 'The Date/time of your departure is required' }
    ],
    emergencyContactPhone: [
      { type: 'required', message: 'An emergency contact phone number for vehicle at show is required'}
    ],
    closerTo: [
      { type: 'required', message: 'Please indicate a parking preference'}
    ]
  };

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              public store: Store<CustomerState>,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.parkForm = this.fb.group({
      arrival: [{value: '', disabled: false}],
      departure: [{value: '', disabled: false}],
      emergencyContactPhone: [{value: '', disabled: false}],
      type: [{value: '', disabled: false}],
      length: [{value: '', disabled: false}],
      parkTypes: this.fb.array([]),
      buddy: [{value: '', disabled: false}],
      closerTo: ['', Validators.required]
    });
    this.parkTypeListArray = this.parkForm.get('parkTypes') as FormArray;

    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {
          this.intId = 1;
          this.api.get('/products?category=8&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  // returned an array
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.productInfo = res.products[0];
                      if (this.productInfo.hasOwnProperty('id')) {
                        this.intItemId = this.productInfo.id;
                      }
                      if (this.productInfo.hasOwnProperty('name')) {
                        this.intItemName = this.productInfo.name;
                      }
                      if (this.productInfo.hasOwnProperty('variants')) {
                        this.productInfo.variants.forEach((aType) => {
                          const formGroup: FormGroup = this.fb.group({
                            quantity: this.fb.control(0)
                          });
                          aType.price = this.productService.getItemPrice(this.productInfo, aType.skuVariant, this.cartService.isMember);
                          aType.subTotal = 0;
                          this.parkTypeListArray.push(formGroup);
                        });
                        this.parkTypes = this.productInfo.variants;
                      }
                      const availabilityMap = this.cartService.productIsAvailable(this.productInfo);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.deadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;


                    }
                  }
                  this.showSpinner = false;
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of handler parking products.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      })
    })

    this.api.get('/committees/18')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  getOptionName(type: any) {
    if ((type.skuVariant === 100) || (type.skuVariant === 150)) {
      return type.optionName;
    } else {
      return '';
    }
  }

  changeQuantity(index: number) {
    const parkQuant = this.parkTypeListArray.controls[index].value;
    const theVariant = this.parkTypes[index];
    theVariant.subTotal = parkQuant.quantity * theVariant.price;

    this.orderTotal = 0;
    let loopIndex = 0;
    this.parkTypeListArray.controls.forEach((aCtl) => {
      const aVariant = this.parkTypes[loopIndex];
      this.orderTotal += aCtl.value.quantity * aVariant.price;
      loopIndex += 1;
    });
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = document.querySelector('form .ng-invalid');
    firstInvalidControl?.focus(); // Focuses the first invalid control
    firstInvalidControl?.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to it
  }
  addToCart() {
    if ((this.orderTotal === 0) || (!this.parkForm.valid)) {
      setTimeout(() => {
        console.log('invalid form');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'Please select the type of parking space you would like and try again.';
      }, 500);
      return;
    }
    const body = {items: []};
    const itemArray = body.items;
// get all grooming spaces
    const dPoints = {
      arrival: this.parkForm.controls.arrival.value,
      departure: this.parkForm.controls.departure.value,
      'Emergency Contact Phone': this.parkForm.controls.emergencyContactPhone.value,
      'Vehicle Type': this.parkForm.controls.type.value,
      'Vehicle Length': this.parkForm.controls.length.value,
      'Parking Buddy': this.parkForm.controls.buddy.value,
      'Closer To': this.parkForm.controls.closerTo.value,
      displayName: ''
    };

    let loopIndex = 0;
    this.parkTypeListArray.controls.forEach((aCtl) => {
      if (aCtl.value.quantity > 0) {
        const aVariant = this.parkTypes[loopIndex];
        const firstReg: CartItem = {
          productId: this.intItemId, productVarId: aVariant.id,
          quantity: aCtl.value.quantity, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      loopIndex += 1;
    });


    this.myProductIsChanging = true;
    this.showSpinner = true;
    this.cartService.addMultipleItemsToCart(body);
    this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.intItemName + ':' + 'multiple', 1);
    setTimeout(() => {
      this.myForm.resetForm();
      this.parkForm.reset({
        arrival: '',
        departure: '',
        emergencyContactPhone: '',
        type: '',
        length: '',
        buddy: '',
        closerTo: ''
      });
      this.parkTypeListArray.controls.forEach((aCtl) => {
        aCtl.value.quantity = 0;
      });
      this.productInfo.variants.forEach(aVariant => {
        aVariant.subTotal = 0;
      });
      this.orderTotal = 0;
    });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
