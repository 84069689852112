import { Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { RollbarService } from '../../services/rollbar.service';
import * as Rollbar from 'rollbar';
import { ApiService } from '../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import * as moment from 'moment-timezone';
import { CartService } from '../../services/cart.service';
import { Store } from '@ngrx/store';
import { CustomerState } from '../../models/customer';
import * as customerReducer from '../../reducers/customer';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ProductService } from '../../services/product.service';
import { CartItem } from '../../models/cart';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';

@Component({
  selector: 'app-international',
  templateUrl: './international.component.html',
  styleUrls: ['./international.component.scss']
})
export class InternationalComponent implements OnInit, OnDestroy {
  @ViewChild('lf', {static: true}) myForm: NgForm;
  intlForm: FormGroup;
  intl1Variant = {id: -1, cost_variants: [], member_cost_variants: [], soldOut: 0, inventory: 0};
  intl2Variant = {id: -1, cost_variants: [], member_cost_variants: [], soldOut: 0, inventory: 0};
  intl3Variant = {id: -1, cost_variants: [], member_cost_variants: [], soldOut: 0, inventory: 0};
  intl4Variant = {id: -1, cost_variants: [], member_cost_variants: [], soldOut: 0, inventory: 0};
  intl1Cost = 0;
  intl2Cost = 0;
  intl3Cost = 0;
  intl4Cost = 0;
  intlTotalCost = 0;
  intl1TotalCost = 0;
  intl2TotalCost = 0;
  intl3TotalCost = 0;
  intl4TotalCost = 0;
  itemName = '';
  itemId = -1;
  myProductIsChanging: boolean;
  productInfo: any;
  orderTotal = 0;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  images = [];
  intId = -1;

  private unsubscribe$ = new Subject<void>();

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private fb: FormBuilder,
              private activeModal: NgbActiveModal,
              private ngZone: NgZone,
              private api: ApiService,
              private productService: ProductService,
              public store: Store<CustomerState>,
              private cartService: CartService,
              private modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) {
    // deadline is Apti 1
    this.myProductIsChanging = false;
    this.deadline = moment(1743566399000).format('dddd, MMMM Do YYYY');
    this.intlForm = this.fb.group({
      intl1Quantity: [{value: 0, disabled: false}],
      intl2Quantity: [{value: 0, disabled: false}],
      intl3Quantity: [{value: 0, disabled: false}],
      intl4Quantity: [{value: 0, disabled: false}]
    });

  }

  ngOnInit() {
    this.showSpinner = true;
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {
          this.intId = 1;
          this.api.get('/products?category=35&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.productInfo = res.products[0];
                      this.showSpinner = false;
                      if (this.productInfo.hasOwnProperty('id')) {
                        this.itemId = this.productInfo.id;
                      }
                      if (this.productInfo.hasOwnProperty('name')) {
                        this.itemName = this.productInfo.name;
                      }
                      const availabilityMap = this.cartService.productIsAvailable(this.productInfo);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.deadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;
                      this.intl1Cost = this.productService.getItemPrice(this.productInfo, 0, this.cartService.isMember);
                      this.intl2Cost = this.productService.getItemPrice(this.productInfo, 200, this.cartService.isMember);
                      this.intl3Cost = this.productService.getItemPrice(this.productInfo, 400, this.cartService.isMember);
                      this.intl4Cost = this.productService.getItemPrice(this.productInfo, 600, this.cartService.isMember);
                      this.intl1Variant = this.productService.getVariant(this.productInfo.variants, 0);
                      this.intl2Variant = this.productService.getVariant(this.productInfo.variants, 200);
                      this.intl3Variant = this.productService.getVariant(this.productInfo.variants, 400);
                      this.intl4Variant = this.productService.getVariant(this.productInfo.variants, 600);

                    }
                  }
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of logo merchandise.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      });
    });
    this.api.get('/committees/57')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });

    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  changeQuantity() {
    const intl1Quant = this.intlForm.controls.intl1Quantity.value;
    this.intl1TotalCost = intl1Quant * this.intl1Cost;
    const intl2Quant = this.intlForm.controls.intl2Quantity.value;
    this.intl2TotalCost = intl2Quant * this.intl2Cost;
    const intl3Quant = this.intlForm.controls.intl3Quantity.value;
    this.intl3TotalCost = intl3Quant * this.intl3Cost;
    const intl4Quant = this.intlForm.controls.intl4Quantity.value;
    this.intl4TotalCost = intl4Quant * this.intl4Cost;
    this.intlTotalCost = this.intl1TotalCost + this.intl2TotalCost + this.intl3TotalCost + this.intl4TotalCost;
    this.orderTotal = this.intlTotalCost;
  }

  addToCart() {
    if (this.intlForm.valid) {
      const intl1Quant = this.intlForm.controls.intl1Quantity.value;
      const intl2Quant = this.intlForm.controls.intl2Quantity.value;
      const intl3Quant = this.intlForm.controls.intl3Quantity.value;
      const intl4Quant = this.intlForm.controls.intl4Quantity.value;
      if ((intl1Quant + intl2Quant + intl3Quant + intl4Quant) === 0) {
        setTimeout(() => {
          this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
          this.errorModalRef.componentInstance.errorMessage = '';
          this.errorModalRef.componentInstance.longErrorMessage = 'Please select the number of international packages you' +
            ' would like and try again.';
        }, 500);
        return;
      }
      const body = {items: []};
      const itemArray = body.items;

      const dPoints = {
        displayName: ''
      };
      if (intl1Quant > 0) {
        const firstReg: CartItem = {
          productId: this.itemId, productVarId: this.intl1Variant.id,
          quantity: intl1Quant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      if (intl2Quant > 0) {
        const firstReg: CartItem = {
          productId: this.itemId, productVarId: this.intl2Variant.id,
          quantity: intl2Quant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      if (intl3Quant > 0) {
        const firstReg: CartItem = {
          productId: this.itemId, productVarId: this.intl3Variant.id,
          quantity: intl3Quant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      if (intl4Quant > 0) {
        const firstReg: CartItem = {
          productId: this.itemId, productVarId: this.intl4Variant.id,
          quantity: intl4Quant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      this.myProductIsChanging = true;
      this.showSpinner = true;
      this.cartService.addMultipleItemsToCart(body);
      this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.itemName + ':' + 'multiple', 1);
      setTimeout(() => {
        this.myForm.resetForm();
        this.intlForm.reset({
          intl1Quantity: 0,
          intl2Quantity: 0,
          intl3Quantity: 0,
          intl4Quantity: 0
        });
      });
    } else {
      setTimeout(() => {
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = '';
        this.errorModalRef.componentInstance.longErrorMessage = 'Please enter a quantity greater than 0 and try again.';
      }, 500);
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  trackByIndexItem(index, item): any {
    return index;
  }

  downloadForm() {
  }}
