import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { ProductService } from '../../services/product.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { CartItem } from '../../models/cart';
import { Store } from '@ngrx/store';
import { CustomerState } from '../../models/customer';
import * as customerReducer from '../../reducers/customer';

@Component({
  selector: 'app-vip',
  templateUrl: './vip.component.html',
  styleUrls: ['./vip.component.scss']
})
export class VipComponent implements OnInit, OnDestroy {
  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  vipForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  myProductIsChanging: boolean;
  itemName = '';
  itemId = -1;
  vipbVariant = {id: -1, cost_variants: [], member_cost_variants: [], soldOut: 0, inventory: 0};
  vipsVariant = {id: -1, cost_variants: [], member_cost_variants: [], soldOut: 0, inventory: 0};
  vipgVariant = {id: -1, cost_variants: [], member_cost_variants: [], soldOut: 0, inventory: 0};
  vipbCost = 0;
  vipsCost = 0;
  vipgCost = 0;
  vipTotalCost = 0;
  vipbTotalCost = 0;
  vipsTotalCost = 0;
  vipgTotalCost = 0;
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  orderTotal = 0;
  deadlineTime = 0;
  deadline = '';
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  intId = -1;

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              public store: Store<CustomerState>,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.vipForm = this.fb.group({
      vipbQuantity: [{value: 0, disabled: false}],
      vipsQuantity: [{value: 0, disabled: false}],
      vipgQuantity: [{value: 0, disabled: false}]
    });
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {
          this.intId = 1;
          this.api.get('/products?category=44&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  // returned an array
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.productInfo = res.products[0];
                      if (this.productInfo.hasOwnProperty('id')) {
                        this.itemId = this.productInfo.id;
                      }
                      if (this.productInfo.hasOwnProperty('name')) {
                        this.itemName = this.productInfo.name;
                      }
                      const availabilityMap = this.cartService.productIsAvailable(this.productInfo);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.deadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;

                      this.vipbCost = this.productService.getItemPrice(this.productInfo, 0, this.cartService.isMember);
                      this.vipsCost = this.productService.getItemPrice(this.productInfo, 100, this.cartService.isMember);
                      this.vipgCost = this.productService.getItemPrice(this.productInfo, 200, this.cartService.isMember);
                      this.vipbVariant = this.productService.getVariant(this.productInfo.variants, 0);
                      this.vipsVariant = this.productService.getVariant(this.productInfo.variants, 100);
                      this.vipgVariant = this.productService.getVariant(this.productInfo.variants, 200);
                    }
                  }
                  this.showSpinner = false;
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of vip package products.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      });
    });
    this.api.get('/committees/58')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  changeQuantity() {
    const vipbQuant = this.vipForm.controls.vipbQuantity.value;
    this.vipbTotalCost = vipbQuant * this.vipbCost;
    const vipsQuant = this.vipForm.controls.vipsQuantity.value;
    this.vipsTotalCost = vipsQuant * this.vipsCost;
    const vipgQuant = this.vipForm.controls.vipgQuantity.value;
    this.vipgTotalCost = vipgQuant * this.vipgCost;
    this.vipTotalCost = this.vipbTotalCost + this.vipsTotalCost + this.vipgTotalCost;
    this.orderTotal = this.vipTotalCost;
  }

  addToCart() {
    if (this.vipForm.valid) {
      const vipbQuant = this.vipForm.controls.vipbQuantity.value;
      const vipsQuant = this.vipForm.controls.vipsQuantity.value;
      const vipgQuant = this.vipForm.controls.vipgQuantity.value;
      if ((vipbQuant + vipsQuant + vipgQuant) === 0) {
        setTimeout(() => {
          this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
          this.errorModalRef.componentInstance.errorMessage = '';
          this.errorModalRef.componentInstance.longErrorMessage = 'Please select the number of vip packages you' +
            ' would like and try again.';
        }, 500);
        return;
      }
      const body = {items: []};
      const itemArray = body.items;

      const dPoints = {
        displayName: ''
      };
      if (vipbQuant > 0) {
        const firstReg: CartItem = {
          productId: this.itemId, productVarId: this.vipbVariant.id,
          quantity: vipbQuant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      if (vipsQuant > 0) {
        const firstReg: CartItem = {
          productId: this.itemId, productVarId: this.vipsVariant.id,
          quantity: vipsQuant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      if (vipgQuant > 0) {
        const firstReg: CartItem = {
          productId: this.itemId, productVarId: this.vipgVariant.id,
          quantity: vipgQuant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      this.myProductIsChanging = true;
      this.showSpinner = true;
      this.cartService.addMultipleItemsToCart(body);
      this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.itemName + ':' + 'multiple', 1);
      setTimeout(() => {
        this.myForm.resetForm();
        this.vipForm.reset({
          vipbQuantity: 0,
          vipsQuantity: 0,
          vipgQuantity: 0
    });
      });
    } else {
      setTimeout(() => {
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = '';
        this.errorModalRef.componentInstance.longErrorMessage = 'Please enter a quantity greater than 0 and try again.';
      }, 500);
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
