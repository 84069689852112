import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { InfoModalComponent } from '../../info-modal/info-modal.component';

@Component({
  selector: 'app-opening',
  templateUrl: './opening.component.html',
  styleUrls: ['./opening.component.scss']
})
export class OpeningComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  openForm: FormGroup;
  showSpinner: boolean;
  topicString: string;
  alreadyGotOne: boolean;
  errorModalRef: NgbModalRef;
  private infoModalRef: NgbModalRef;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman';
  private unsubscribe$ = new Subject<void>();
  accountValidationMessages = {
    emailAddress: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    name: [
      { type: 'required', message: 'Your name is required' }
    ],
    address: [
      { type: 'required', message: 'Your address is required' }
    ],
    phone: [
      { type: 'required', message: 'Your phone number is required' }
    ],
    rcName: [
      { type: 'required', message: 'Your phone number is required' }
    ]
  };

  constructor(private modalService: NgbModal,
              private fb: FormBuilder,
              private api: ApiService,
              private ngZone: NgZone) { }

  ngOnInit() {
    this.showSpinner = false;
    this.openForm = this.fb.group({
      name: [{value: '', disabled: false}, Validators.required],
      address: [{value: '', disabled: false}, Validators.required],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]],
      rcName: [{value: '', disabled: false}, Validators.required],
      phone: ['', Validators.required]
    });
    this.api.get('/committees/31')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
  }

  sendForm() {
    if (this.openForm.valid) {
      this.alreadyGotOne = false;
      this.showSpinner = true;
      this.api.post('/forms', {
        committeeId: 31,
        formData: {
          fullName: this.openForm.controls.name.value,
          fullAddress: this.openForm.controls.address.value,
          emailAddress: this.openForm.controls.emailAddress.value,
          phone: this.openForm.controls.phone.value,
          regionalClub: this.openForm.controls.rcName.value
        }})
        .subscribe(
          (res) => this.formSent(res),
          (err) => this.formSendFailed(err)
        );
    } else {
      setTimeout(() => {
        console.log('invalid adtype');
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = '';
        this.errorModalRef.componentInstance.longErrorMessage = 'Please enter all required fields and try again.';
      }, 500);
    }
  }

  private formSent(res: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.myForm.resetForm();
      this.openForm.reset({
        name: '',
        address: '',
        emailAddress: '',
        phone: '',
        rcName: ''
      });
      this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
      this.infoModalRef.componentInstance.infoTitle = 'Message Sent.';
      this.infoModalRef.componentInstance.infoMessage = 'Your message was successfully sent to the Opening Ceremonies coordinator.' +
        ' Thank you for participating.';
    }, 500);
  }

  private formSendFailed(err: any) {
    this.showSpinner = false;
    setTimeout(() => {
      console.log('invalid quantity added');
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = '';
      this.errorModalRef.componentInstance.longErrorMessage = 'An error occurred while submitting this form.  Please try again.';
    }, 500);
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
