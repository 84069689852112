import { Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RollbarService } from '../../services/rollbar.service';
import * as Rollbar from 'rollbar';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { Store } from '@ngrx/store';
import { CustomerState } from '../../models/customer';
import * as customerReducer from '../../reducers/customer';

@Component({
  selector: 'app-post-show-merch',
  templateUrl: './post-show-merch.component.html',
  styleUrls: ['./post-show-merch.component.scss']
})
export class PostShowMerchComponent implements OnInit, OnDestroy {
  @ViewChild('modalImageBrowser', { static: true }) modalImageBrowser?: NgbModal;

  items: any[];
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  noProductsAvailable = false;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  images = [];
  intId = -1;

  private unsubscribe$ = new Subject<void>();

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private activeModal: NgbActiveModal,
              private ngZone: NgZone,
              private router: Router,
              private api: ApiService,
              public store: Store<CustomerState>,
              private cartService: CartService,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.showSpinner = true;
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {
          this.intId = 1;
          this.api.get('/products?category=46&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.items = res.products;
                      const prod = res.products[0];
                      this.showSpinner = false;
                      const availabilityMap = this.cartService.productIsAvailable(prod);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.deadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;

                      this.noProductsAvailable = false;
                    } else {
                      // no products to show:
                      this.showSpinner = false;
                      this.noProductsAvailable = true;
                    }
                  }
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of post show merchandise.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      });
    });
    this.api.get('/committees/61')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openImageBrowser(variantImageArray) {
    this.images = variantImageArray.map((variantImage) => variantImage.path);
    this.modalService.open(this.modalImageBrowser, { windowClass: 'modal-preview', centered: true });
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  trackByIndexItem(index, item): any {
    return index;
  }

  downloadForm() {
  }

  gotoTop20() {
    this.router.navigateByUrl('/shop/top20');
  }
}
