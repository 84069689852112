<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ml-1" href="assets/2025/2025OpeningForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-12">
      <h3>OPENING CEREMONIES</h3>
      <p>Tuesday afternoon&apos;s Opening Ceremony will begin with a parade of NCA Regional Clubs.
        Each Regional Club is asked to participate by providing representatives and their Newfs to a parade in the main
        conformation ring. While carts are optional, we encourage clubs to get creative with their representation,
        keeping in mind the theme of Rich History – Bright Future. Large flags with each club&apos;s logo will be provided
        and displayed on the show grounds in tribute throughout the week.  For our international guests that would like
        to participate, please let Donna Thibault know so flags and poles can be coordinated.</p>
    </div>
    <div class="loaded-content" [class.blurred]="showSpinner">
      <div class="col-md-10 col-sm-12">
        <form id="openForm" [formGroup]="openForm" #lf="ngForm">
          <h5>Yes, My Regional Club would like to participate!</h5>
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Contact Name" formControlName="name">
              <mat-error *ngFor="let validation of accountValidationMessages.name">
                <mat-error class="error-message"
                           *ngIf="openForm.get('name').hasError(validation.type) && (openForm.get('name').dirty || openForm.get('name').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Phone Number (at the show)" formControlName="phone">
              <mat-error *ngFor="let validation of accountValidationMessages.phone">
                <mat-error class="error-message"
                           *ngIf="openForm.get('phone').hasError(validation.type) && (openForm.get('phone').dirty || openForm.get('phone').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Full Address" formControlName="address">
              <mat-error *ngFor="let validation of accountValidationMessages.address">
                <mat-error class="error-message"
                           *ngIf="openForm.get('address').hasError(validation.type) && (openForm.get('address').dirty || openForm.get('address').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Email Address" formControlName="emailAddress">
              <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                <mat-error class="error-message"
                           *ngIf="openForm.get('emailAddress').hasError(validation.type) && (openForm.get('emailAddress').dirty || openForm.get('emailAddress').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput type="text" formControlName="rcName"
                     placeholder="Name of Regional Club">
            </mat-form-field>
          </div>
          <button type="button" (click)="sendForm()" class="btn btn-primary">Send Form</button>
        </form>
      </div>
    </div>
  </div>
</div>
