<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 mr-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ml-1" href="assets/2025/2025WorkingDogForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ml-1" href="mailto:{{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-6">
      <h3>WORKING DOG FORUM</h3>
      <p>Please come join the Working Dog Committee for the Working Dog Forum for 2025!</p>
      <p>We will be having an open discussion at 5 pm on Wednesday, April 30 about all things working.  Bring your questions, ideas, thoughts, and kudos.</p>
      <p>As an incredibly special recognition, NCNE will be honoring all dogs who have earned a 2024 water or draft title at this event.  If you have earned a new working dog title in 2024, you won’t want to miss this! All titlists will be recognized but a special gift will be given to those who achieved a new working dog title during 2024.</p>
      <p>We encourage you to complete the electronic form to let us know you will be coming.  You can also fill out the paper form and submit if needed.</p>
    </div>
    <div class="col-md-6">
      <div class="wrapper col-md-6">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-10 col-sm-12">
            <form id="wdForm" [formGroup]="wdForm" #lf="ngForm">
              <p>Yes, I am coming to the Working Dog Seminar!</p>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Name" formControlName="name">
                  <mat-error *ngFor="let validation of accountValidationMessages.name">
                    <mat-error class="error-message"
                               *ngIf="wdForm.get('name').hasError(validation.type) && (wdForm.get('name').dirty || wdForm.get('name').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Phone Number (at the show)" formControlName="phone">
                  <mat-error *ngFor="let validation of accountValidationMessages.phone">
                    <mat-error class="error-message"
                               *ngIf="wdForm.get('phone').hasError(validation.type) && (wdForm.get('phone').dirty || wdForm.get('phone').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row d-none">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Address" formControlName="address">
                  <mat-error *ngFor="let validation of accountValidationMessages.address">
                    <mat-error class="error-message"
                               *ngIf="wdForm.get('address').hasError(validation.type) && (wdForm.get('address').dirty || wdForm.get('address').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Email Address" formControlName="emailAddress">
                  <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                    <mat-error class="error-message"
                               *ngIf="wdForm.get('emailAddress').hasError(validation.type) && (wdForm.get('emailAddress').dirty || wdForm.get('emailAddress').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row mt-3 mb-0 d-none">
                <mat-form-field class="inputField-half-width">
                  <input matInput id="numberInParty" type="number" formControlName="numberInParty"
                         placeholder="Number in your party">
                  <mat-error *ngFor="let validation of accountValidationMessages.numberInParty">
                    <mat-error class="error-message"
                               *ngIf="wdForm.get('numberInParty').hasError(validation.type) && (wdForm.get('numberInParty').dirty || wdForm.get('numberInParty').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="form-row mb-3 d-none">
                <mat-form-field class="inputField-full-width">
                  <mat-label>Are you an NCA Member?</mat-label>
                  <mat-select formControlName="isMember" placeholder="NCA Member?">
                    <mat-option value="yes">Yes</mat-option>
                    <mat-option value="no">No</mat-option>
                  </mat-select>
                  <mat-error *ngFor="let validation of accountValidationMessages.isMember">
                    <mat-error class="error-message"
                               *ngIf="wdForm.get('isMember').hasError(validation.type) && (wdForm.get('isMember').dirty || wdForm.get('isMember').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row mb-3 d-none">
                <mat-form-field class="inputField-full-width">
                  <mat-label>Are you a member of a Regional Club?</mat-label>
                  <mat-select formControlName="isRCMember" placeholder="Regional Club Member?">
                    <mat-option value="yes">Yes</mat-option>
                    <mat-option value="no">No</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-row d-none">
                <mat-form-field class="inputField-full-width">
                  <input matInput type="text" formControlName="rcName"
                         placeholder="Name of Regional Club">
                </mat-form-field>
              </div>
              <div class="form-row d-none">
                <div class="col">
                  <p>I am a (please check all that apply):</p>
                </div>
              </div>
              <div class="form-row ml-3 d-none">
                <div class="col">
                  <input class="me-2" type="checkbox" id="exhibitor" name="exhibitor"
                         formControlName="exhibitor">Working Event Competitor
                </div>
              </div>
              <div class="form-row ml-3 d-none">
                <div class="col">
                  <input class="me-2" type="checkbox" id="testCommittee" name="testCommittee"
                         formControlName="testCommittee">Test Committee Member
                </div>
              </div>
              <div class="form-row ml-3 d-none">
                <div class="col">
                  <input class="me-2" type="checkbox" id="waterJudge" name="waterJudge"
                         formControlName="waterJudge">NCA Water Rescue Judge
                </div>
              </div>
              <div class="form-row ml-3 d-none">
                <div class="col">
                  <input class="me-2" type="checkbox" id="draftJudge" name="draftJudge"
                         formControlName="draftJudge">NCA Draft Judge
                </div>
              </div>
              <div class="form-row d-none">
                <mat-form-field class="inputField-full-width">
                  <input matInput type="text" formControlName="topics"
                         placeholder="Please share the topics you are interested in discussing">
                </mat-form-field>
              </div>
              <button type="button" (click)="sendForm()" class="btn btn-primary">Send Form</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

