import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { ProductService } from '../../services/product.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import { Store } from '@ngrx/store';
import { CustomerState } from '../../models/customer';
import * as customerReducer from '../../reducers/customer';

@Component({
  selector: 'app-rvparking',
  templateUrl: './rvparking.component.html',
  styleUrls: ['./rvparking.component.scss']
})
export class RvparkingComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  parkForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  infoModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  myProductIsChanging: boolean;
  intItemName = '';
  intItemId = -1;
  parkTypes = [];
  selectedType = -1;
  orderTotal = 0;
  intItemCost = 0.0;
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  basePrice = 0.0;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  intId = -1;

  accountValidationMessages = {
    arrival: [
      { type: 'required', message: 'The Date/time of your arrival is required' }
    ],
    departure: [
      { type: 'required', message: 'The Date/time of your departure is required' }
    ],
    parkType: [
      {type: 'required', message: 'Please select a type of parking'}
    ],
    numberNights: [
      { type: 'min', message: 'Please indicate the number of nights of your stay'}
    ]

  };

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              public store: Store<CustomerState>,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.parkForm = this.fb.group({
      arrival: [{value: '', disabled: false}],
      departure: [{value: '', disabled: false}],
      type: [{value: '', disabled: false}],
      length: [{value: '', disabled: false}],
      addVehicle: [{value: '', disabled: false}],
      addVehicleLength: [{value: '', disabled: false}],
      name: [{value: '', disabled: false}],
      parkType: [this.selectedType],
      hotel: [{value: '', disabled: false}],
      numberNights: [{value: 0, disabled: false}, Validators.min(1)]
    });
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {
          this.intId = 1;
          this.api.get('/products?category=9&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  // returned an array
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.productInfo = res.products[0];
                      if (this.productInfo.hasOwnProperty('id')) {
                        this.intItemId = this.productInfo.id;
                      }
                      if (this.productInfo.hasOwnProperty('name')) {
                        this.intItemName = this.productInfo.name;
                      }
                      if (this.productInfo.hasOwnProperty('variants')) {
                        this.parkTypes = this.productInfo.variants;
                        if (this.parkTypes.length > 0) {
                          this.selectedType = this.parkTypes[0].id;
                          const parkTypeControl = this.parkForm.get('parkType');
                          parkTypeControl?.setValue(this.selectedType);
                          parkTypeControl?.updateValueAndValidity()
                          this.intItemCost = this.productService.getItemPrice(this.productInfo, this.parkTypes[0].skuVariant, this.cartService.isMember);
                          this.orderTotal = 0;
                        }
                      }
                      const availabilityMap = this.cartService.productIsAvailable(this.productInfo);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.deadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;

                    }
                  }
                  this.showSpinner = false;
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of seating products.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      });
    });
    this.api.get('/committees/38')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  changeParkingType(e) {
    const theSelectedParkType = parseInt(this.parkForm.controls.parkType.value, 10);
    const theProductSelected = this.parkTypes.filter((parkType) => parkType.id === theSelectedParkType);
    if (theProductSelected.length > 0) {
      const aProd = theProductSelected[0];
      this.intItemCost = this.productService.getItemPrice(this.productInfo, aProd.skuVariant, this.cartService.isMember);
      this.orderTotal = this.intItemCost;
    }
  }

  changeNights() {
    const theSelectedParkType = parseInt(this.parkForm.controls.parkType.value, 10);
    const numberOfNights = this.parkForm.controls.numberNights.value;
    // this year, we're charging by the number of nights
    const theProductSelected = this.parkTypes.filter((parkType) => parkType.id === theSelectedParkType);
    if (theProductSelected.length > 0) {
      const aProd = theProductSelected[0];
      this.intItemCost = this.productService.getItemPrice(this.productInfo, aProd.skuVariant, this.cartService.isMember);
      this.orderTotal = this.intItemCost * numberOfNights;
    }
  }
  addToCart() {
    this.parkForm.markAllAsTouched();
    if ((this.parkForm.invalid) || (this.orderTotal == 0)) {
      return;
    }
    const dPoints = {arrival: this.parkForm.controls.arrival.value,
      departure: this.parkForm.controls.departure.value,
      'Vehicle Type': this.parkForm.controls.type.value,
      'Vehicle Length': this.parkForm.controls.length.value,
      'Additional Vehicle': this.parkForm.controls.addVehicle.value,
      'Additional Vehicle Length': this.parkForm.controls.addVehicleLength.value,
      'Camping Buddy': this.parkForm.controls.name.value,
      'Staying at hotel': this.parkForm.controls.hotel.value,
      'Number of Nights': this.parkForm.controls.numberNights.value,
      displayName: ''
    };

    const product = {
      productId: this.intItemId,
      productVariantId: parseInt(this.parkForm.controls.parkType.value, 10),
      quantity: this.parkForm.controls.numberNights.value,
      dataPoints: JSON.stringify(dPoints)
    };
    this.myProductIsChanging = true;
    this.showSpinner = true;
    this.cartService.addItemToCart(product);
    this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.intItemName, 1);
    setTimeout(() => {
      this.myForm.resetForm();
      this.selectedType = this.parkTypes[0].id;
      this.parkForm.reset({
        arrival: '',
        departure: '',
        type: '',
        length: '',
        addVehicle: '',
        addVehicleLength: '',
        name: '',
        parkType: this.selectedType,
        hotel: '',
        numberNights: 0
      });
      const parkTypeControl = this.parkForm.get('parkType');
      parkTypeControl?.setValue(this.selectedType);
      parkTypeControl?.updateValueAndValidity()
      this.orderTotal = 0;
    });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
